<template>
  <v-container fluid>

    <!-- table -->
    <card :title="$t('activity.title')">
      <template v-slot:actions>
        <v-btn small color="primary" @click="createActivity()"><v-icon left small>fa fa-plus</v-icon> {{ $t('actions.create') }}</v-btn>
      </template>


      <grid-view ref="grid" :headers="headers" endpoint="activity?expand=sector" model="activity">
        <template v-slot:item._actions="{ item }">
          <v-btn @click="viewActivity(item)" icon small><v-icon small>mdi-eye</v-icon></v-btn>
          <v-btn @click="editActivity(item)" icon small><v-icon small>mdi-pencil</v-icon></v-btn>
          <v-btn @click="deleteActivity(item)" icon small><v-icon x-small>fa fa-trash</v-icon></v-btn>
        </template>
        <template v-slot:item.name="{ item }">
          {{ translate(item.name) }}
        </template>
        <template v-slot:item.sector="{ item }">
          {{ translate(item.sector.name) }}
        </template>
      </grid-view>
    </card>


    <!-- view dialog -->
    <v-dialog v-model="viewDialog" max-width="600">
      <v-card v-if="activity">
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">
              {{ translate(activity.name)}}
            </h3>
            <div>{{activity}}</div>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-btn text @click="edit()">{{$t('actions.edit')}}</v-btn>
          <v-spacer />
          <v-btn color="primary" @click="viewDialog = false">{{$t('actions.close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog -->
    <v-dialog v-model="editDialog" max-width="600">
       <Form :activityId="editedActivityId" v-if="editDialog" @save="onDialogSave" />
    </v-dialog>

  </v-container>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import Form from "@/pages/activity/Form";
import formatter from '@/mixins/formatter';
import GridView from "@/components/GridView";
import Card from "@/components/Card";
import {setPageTitle} from "@/utils/meta";

@Component({ components: {Card, GridView, Form }, mixins: [formatter] })
export default class ActivityIndex extends Vue {

  viewDialog = false;
  editDialog = false;
  activity = null;
  editedActivityId = null;

  created() {
    setPageTitle(this.$i18n.t('models.activity.selves'));
  }

  get headers() {
    return [
      { text: '', value: '_actions', },
      { text: this.$i18n.t('models.activity.code'), value: 'code', width: 1 },
      { text: this.$i18n.t('models.sector.domain'), value: 'sector.domain', width: 1 },
      { text: this.$i18n.t('models.activity.sector'), value: 'sector', },
      { text: this.$i18n.t('models.activity.self'), value: 'name', sort: this.i18nSort },
    ];
  }

  viewActivity(activity) {
    this.$api.get( `activity/${activity.id}?expand=sector` ).then(res => {
      this.activity = res.data;
      this.viewDialog = true;
    });
  }

  createActivity() {
    this.editedActivityId = null;
    this.editDialog = true;
  }

  editActivity(activity) {
    this.editedActivityId = activity.id;
    this.editDialog = true;
  }

  deleteActivity(activity) {
    this.$root.$confirm.open('Delete activity', 'Are you sure you want to delete this activity ?', {}).then((confirm) => {
      if (confirm) {
        this.$api.delete(`activity/${activity.id}`).then(() => {
          this.$refs.grid.getModels();
        }).catch(() => {
          alert("Unable to remove activity");
        });
      }
    });
  }

  onDialogSave(activity) {
    this.$refs.grid.getModels();
    this.editDialog = false;
    this.editedActivityId = null;
  }

}
</script>
